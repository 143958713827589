import { useEffect, useRef } from 'react';

import type { DialogsStateContainer } from '@confluence/dialogs';
import { useObjectSidebar } from '@confluence/object-sidebar-api';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import {
	useInlineCommentsDispatchContext,
	useCommentsContentState,
	useReattachComment,
} from '@confluence/comment-context';
import { useAnnotationsDispatch } from '@confluence/annotation-provider-store';

import { getLegacyConvertedSelectionObj } from './getLegacyConvertedSelectionObj';

export const useCreateCommentAction = ({
	contentId,
	isFabric,
	sendKeyboardShortcutAnalytics,
}: {
	contentId: string;
	isFabric: boolean;
	sendKeyboardShortcutAnalytics: (shortcut: string) => void;
}) => {
	const [{ isObjectSidebarShown }, { hideObjectSidebar }] = useObjectSidebar();
	const { setSelectionOptionsForCreateEditor } = useAnnotationsDispatch();
	const { createComment } = useInlineCommentsDispatchContext();
	const { commentToReattach } = useReattachComment();
	const { hasContentChanged } = useCommentsContentState();

	const content = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		content.current = document.querySelector('div#content');
	}, []);

	const createReactInlineCommentHighlight = () => {
		if (isObjectSidebarShown) {
			hideObjectSidebar();
		}
		if (isFabric) {
			setSelectionOptionsForCreateEditor();
		} else {
			if (content.current) {
				createComment(
					getLegacyConvertedSelectionObj({
						content,
						contentId,
						commentToReattach,
					}),
				);
			}
		}
	};

	const handleCommentButtonClick = ({
		dialogs,
		shortcut,
	}: {
		dialogs: DialogsStateContainer;
		shortcut?: string;
	}) => {
		if (hasContentChanged) {
			dialogs.showModal(CommentWarningDialog, {
				onConfirm: () => createReactInlineCommentHighlight(),
			});
		} else {
			createReactInlineCommentHighlight();
		}

		if (shortcut) {
			sendKeyboardShortcutAnalytics(shortcut);
		}
	};

	return { handleCommentButtonClick };
};
