import React, { Fragment, useCallback, useEffect, useMemo, useContext } from 'react';
import type { ComponentProps, FC } from 'react';
import { useInView } from 'react-intersection-observer';

import type { RendererAppearance } from '@atlaskit/renderer';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { ViewCommentShortcutListener } from '@confluence/comments-shortcuts';
import { AIFloatingContextMenuLoadable as AIFloatingContextMenu } from '@confluence/ai-floating-context-menu';
import { expVal, expValEquals } from '@confluence/feature-experiments';
import { GenericPremiumTipLoadable } from '@confluence/premium-trial/entry-points/GenericPremiumTipLoadable';
import {
	PageCommentsCountProvider,
	ReactionsProvider,
	NewCommentsProvider,
} from '@confluence/comment-context';
import { ArchivedPageBanner } from '@confluence/archive-pages';
import { ContentViewedEvent, ContentRefetchProvider } from '@confluence/content-body';
import {
	CUSTOM_HEADER_FOOTER_TYPES,
	CustomHeaderFooterLoader,
} from '@confluence/custom-header-footer';
import { fg } from '@confluence/feature-gating';
import { LocalActivityViewPageTracker } from '@confluence/local-activity';
import {
	getRendererCoverPictureInfo,
	RendererPageCoverPicture,
} from '@confluence/page-cover-picture';
import { QuickReload, PageMode } from '@confluence/quick-reload';
import { ScrollToPageStart } from '@confluence/scroll';
import {
	LivePageComponentLoader as LivePageComponentClient,
	LivePageComponentServer,
	LivePagesChangeboardingManagerLoader,
} from '@confluence/live-pages';
import { ConvertToLivePageFlag } from '@confluence/live-pages-features/entry-points/ConvertToLivePageFlag';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { LIVE_PAGE_SUBTYPE } from '@confluence/live-pages-utils';
import { useSessionData } from '@confluence/session-data';
import { EndOfPageRecommendation } from '@confluence/page-recommendations';
import { InlineCommentsHighlighterLoader } from '@confluence/inline-comments/entry-points/InlineCommentsHighlighterLoader';
import { ViewPageLabelsLoader } from '@confluence/labels';
import {
	getAutoConvertionInfo,
	AutoConversionByLineContextProvider,
} from '@confluence/editor-conversion';
import { LoadingPriority } from '@confluence/loadable';
import { ContentOwnershipContextProvider } from '@confluence/content-ownership';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import type { CachedPageData } from '@confluence/live-pages-utils/entry-points/getCachedPageData';
import { useLivePageStoreActions } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { ReadingAidsAcronymsHighlighter } from '@confluence/contextual-reading-aids/entry-points/readingAidsAcronymsHighlighter';
import { PageCommentsPubSubHandler } from '@confluence/comments-pubsub/entry-points/pageComments';
import { RendererActionsProvider } from '@confluence/renderer-actions';
import {
	DocumentUpdateStatusProvider,
	AnnotationsProvider,
} from '@confluence/annotation-provider-store';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import {
	usePremiumTipState,
	useEligibilityBiteSizedPremiumMessages,
} from '@confluence/premium-trial/entry-points/BiteSizeOnboarding';
import { AutomationPremiumTipLoader } from '@confluence/premium-trial/entry-points/AutomationPremiumTipLoader';
import { ObjectSidebarControl } from '@confluence/object-sidebar-components';
import { useSetContentLastModifiedTimestamp } from '@confluence/issue-create-side-panel';
import { useCommentsPanelActions } from '@confluence/comments-panel-utils';
import { useCommentsDataActions } from '@confluence/comments-data';
import { NativeCollabPresenceStateContainer } from '@confluence/native-collab';
import { ChoreographerAssetsContextProvider } from '@confluence/choreographer-services';
import { useConnectAppsData } from '@confluence/web-panel-location/entry-points/useConnectAppsData';
import { ATL_GENERAL } from '@confluence/web-item-location/entry-points/WEB_FRAGMENT_LOCATIONS';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';
import {
	ExperienceTrackerContext,
	VIEW_PAGE_EXPERIENCE,
	LIVE_PAGE_PUBLISH_EXPERIENCE,
} from '@confluence/experience-tracker';
import { isSpaceOverview } from '@confluence/named-routes';
import type { SuspenseableDataFetcher } from '@confluence/query-preloader-tools';
import { setIsLivePage } from '@confluence/unique-page-load-id';
import { ObjectCommentWrapper } from '@confluence/object-comments';

import type { BaseViewPageProps } from './BaseViewPageProps';
import { ContentTitleWithByLine } from './ContentTitleWithByLine';
import { SatisfactionSurveysLoader } from './SatisfactionSurveysLoader';
import { ViewPageFooterComments } from './ViewPageFooterComments';
import { ViewPageInlineComments } from './ViewPageInlineComments';
import { ViewPageContainer } from './ViewPageContainer';
import { ViewPageContentLoader } from './ViewPageContentLoader';
import { ViewPageReactions } from './ViewPageReactions';
import { useRenderLivePageEarly } from './useRenderLivePageEarly';
import { Divider } from './Divider';

// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
const LivePageComponent = process.env.REACT_SSR ? LivePageComponentServer : LivePageComponentClient;

const setLoadingPriority = (hasAllowedFeature: boolean) => {
	if (isEmbeddedConfluence_DO_NOT_USE()) {
		return hasAllowedFeature ? LoadingPriority.AFTER_PAINT : LoadingPriority.LAZY;
	}
	return null;
};

type ContentTitleAndByLineOptions = Pick<
	ComponentProps<typeof ContentTitleWithByLine>,
	'hasByLineContributors' | 'hasByLineExtensions' | 'headingContainer'
>;

type Props = BaseViewPageProps & {
	contentHeader?: React.ReactNode;
	hasPageComments?: boolean;
	hasSatisfactionSurveys?: boolean;
	hasInlineComments?: boolean;
	hasLabels?: boolean;
	hasReactions?: boolean;
	hasEOPRecs?: boolean;
	hasQuickReload?: boolean;
	appearance?: string;
	classicComments?: React.ComponentType<any>;
	hasInlineActions?: boolean;
	contentScreenStyles?: ComponentProps<typeof ViewPageContainer>['contentScreenStyles'];
	eventHandlers?: React.ComponentProps<typeof ViewPageContentLoader>['eventHandlerOverrides'];
	titleAndByLineOptions?: ContentTitleAndByLineOptions;
	children?: React.ReactNode;
	setHasCoverPicture?: (hasCoverPicture: boolean) => void;
	isEmbeddedPage?: boolean;
	ClassicEditorContextProviderLoader?: React.ComponentType<any>;
	renderLivePageAsViewPage?: boolean;
	cachedPageData?: CachedPageData;
	viewPageDataResource?: SuspenseableDataFetcher | null;
};

// NOTE: `contentId` represents the user's intended page but not always the displayed one. During transition to a new page, the component still renders the previous page until ViewPageContainer updates the contentQueryData.
//       While fetching new contentQueryData, the prior contentQueryData is displayed, causing a brief mismatch between contentId and the contentQueryData's contentId.
export const ViewPageCommon: FC<Props> = ({
	spaceKey,
	contentId,
	contentHeader,
	contentScreenStyles,
	classicComments,
	eventHandlers,
	titleAndByLineOptions,
	hasPageComments = true,
	hasSatisfactionSurveys = true,
	hasInlineComments = true,
	hasInlineActions = true,
	hasLabels = true,
	hasReactions = true,
	hasEOPRecs = true,
	hasQuickReload = true,
	appearance,
	children,
	setHasCoverPicture,
	isEmbeddedPage,
	ClassicEditorContextProviderLoader,
	renderLivePageAsViewPage = false,
	cachedPageData = {},
	viewPageDataResource,
}) => {
	if (viewPageDataResource) {
		viewPageDataResource.read();
	}

	const { locale } = useSessionData();
	const setContentLastModifiedTimestamp = useSetContentLastModifiedTimestamp();
	const { isLivePagesFeatureEnabled, livePageReleasePhase } = useIsLivePagesFeatureEnabled();
	const { isLivePage: isLivePageOnSSR } = getSSRRenderInputs();
	const { resetInitialDataLoadedMap } = useCommentsPanelActions();
	const { clearCommentsData } = useCommentsDataActions();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
		contentId,
	});

	const isReadingAidsAutoHighlightEnabled =
		(isAIEnabledForContent || isRovoEnabledForContent) && locale.startsWith('en');

	// Setup for A/A experiment for testing stratified sampling with Statsig
	expVal('cc_stratified_sampling_exp_aa', 'cohort', 'not-controlled');

	let stickyHeader = contentHeader;

	const isLivePage = useIsCurrentPageLive();
	const { setIsLive } = useLivePageStoreActions();
	const [pageCommentsSectionRef, pageCommentsSectionInView] = useInView();

	const shouldShowBylineReactions =
		hasReactions &&
		!isLivePage &&
		expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test');

	const { overrideUFORouteName } = useRouteActions();

	const setLivePageGlobalState = useCallback(
		(isContentLive: boolean) => {
			void setIsLive(isContentLive);
			void setIsLivePage(isContentLive); // page load state used for analytics context (e.g. for experience tracking)

			if (isContentLive) {
				overrideUFORouteName('live-edit');
			}
		},
		[setIsLive, overrideUFORouteName],
	);

	useEffect(() => {
		if (!isLivePage) return; // no cleanup needed

		// This is the cleanup function that runs when the component unmounts
		return () => {
			setLivePageGlobalState(false);
		};
	}, [isLivePage, setLivePageGlobalState]);

	// Update view-page experience with `isLivePage` && `livePageReleasePhase` attributes to ensure all future experience fails denote whether it was on a live page
	useEffect(() => {
		const experienceState = experienceTracker.getExperienceState(VIEW_PAGE_EXPERIENCE);

		if (
			experienceState?.attributes?.contentId === contentId &&
			experienceState?.attributes?.isLivePage !== isLivePage
		) {
			experienceTracker.updateAttributes({
				name: VIEW_PAGE_EXPERIENCE,
				attributes: { isLivePage, livePageReleasePhase },
			});
		}
	}, [contentId, isLivePage, livePageReleasePhase, experienceTracker]);

	// stickyHeader needs to be removed based on live page state since it's used in ViewPageContainer props
	if (isLivePage) {
		stickyHeader = undefined;
	}
	const {
		renderLivePageEarly,
		isLivePageCacheOutdated,
		isFastLoadedLivePageArchived,
		isLivePageFastLoadedFromSSR,
	} = useRenderLivePageEarly({
		contentId,
		cachedSubType: cachedPageData.subType,
		cachedIsArchived: cachedPageData.isArchived,
		renderLivePageAsViewPage,
	});

	useEffect(() => {
		if (renderLivePageEarly) {
			setLivePageGlobalState(true);
		}
	}, [renderLivePageEarly, setLivePageGlobalState]);

	useEffect(() => {
		return () => {
			if (fg('confluence-frontend-comments-panel')) {
				resetInitialDataLoadedMap();
				clearCommentsData();
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	const { showPrompt, source } = usePremiumTipState();
	const { isFeatureFlagEnabled: isPremiumTipPromptEligible } =
		useEligibilityBiteSizedPremiumMessages();
	const displayGenericPremiumTip =
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		isPremiumTipPromptEligible && !process.env.REACT_SSR && !showPrompt;

	let livePageFastLoadedSource = '';
	if (renderLivePageEarly) {
		if (isLivePageFastLoadedFromSSR) {
			livePageFastLoadedSource = 'SSR';
		} else if (cachedPageData?.gqlType) {
			livePageFastLoadedSource = `GQL:${cachedPageData.gqlType}`;
		} else {
			livePageFastLoadedSource = 'Unknown';
		}
	}

	const livePagePerfData = useMemo(
		() => ({
			renderLivePageEarly,
			isLivePageCacheOutdated,
			isFastLoadedLivePageArchived,
			isLivePageFastLoadedFromSSR,
			livePageFastLoadedSource,
			contentExtensionsCount: {},
		}),
		[
			renderLivePageEarly,
			isLivePageCacheOutdated,
			isFastLoadedLivePageArchived,
			isLivePageFastLoadedFromSSR,
			livePageFastLoadedSource,
		],
	);

	const connectAppsData = useConnectAppsData({
		contentId,
		location: ATL_GENERAL,
	});

	const customPerfData = !fg('confluence_preload_webpanel_atl_general')
		? livePagePerfData
		: {
				...livePagePerfData,
				...connectAppsData,
			};

	const isSpaceOverviewPage = isSpaceOverview();

	return (
		<Fragment>
			<ContentOwnershipContextProvider>
				<NewCommentsProvider>
					<PageCommentsCountProvider contentId={contentId}>
						<DocumentUpdateStatusProvider>
							<ContentRefetchProvider>
								<RendererActionsProvider>
									<AnnotationsProvider>
										<ChoreographerAssetsContextProvider>
											{renderLivePageEarly ? (
												<NativeCollabPresenceStateContainer scope={contentId}>
													<LivePageComponent
														contentId={contentId}
														spaceKey={spaceKey}
														ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
														customPerfData={customPerfData}
														loadingPriority={setLoadingPriority(true)}
													/>
												</NativeCollabPresenceStateContainer>
											) : (
												<ViewPageContainer
													spaceKey={spaceKey}
													contentId={contentId}
													stickyHeader={stickyHeader}
													contentScreenStyles={contentScreenStyles}
													customPerfData={customPerfData}
												>
													{({ isThemed, contentQueryData, contentQueryError }) => {
														const contentStatus = contentQueryData?.content?.nodes?.[0]?.status;
														const contentType = contentQueryData?.content?.nodes?.[0]?.type;
														const contentSubType = contentQueryData?.content?.nodes?.[0]?.subType;
														const spaceId = contentQueryData?.content?.nodes?.[0]?.space?.id;
														const contentIdFromQuery = contentQueryData?.content?.nodes?.[0]?.id;
														const lastModifiedDate =
															contentQueryData?.content?.nodes?.[0]?.metadata?.lastModifiedDate;
														void setContentLastModifiedTimestamp(lastModifiedDate);
														const pageWidthType =
															contentQueryData?.content?.nodes?.[0]?.appearancePublished?.nodes?.[0]
																?.value || `"default"`;
														const isFabricPage = Boolean(
															contentQueryData?.content?.nodes?.[0]?.body?.dynamic
																?.representation === 'atlas_doc_format',
														);
														const showAIButton =
															(contentType === 'page' || contentType === 'blogpost') &&
															!isEmbeddedPage;

														const showStandaloneAIButton =
															!fg('confluence_ai_uber_button_in_object_sidebar') &&
															!fg('confluence_frontend_object_sidebar') &&
															expValEquals(
																'cc_ai_consumption_ux_button',
																'button-placement',
																'bottom',
															);
														const showAIButtonForTinyMCE =
															!isFabricPage && fg('confluence_frontend_object_header');

														// getting info cover picture needs
														const {
															coverPicture,
															contentAppearance,
															hasCoverPicture,
															coverPictureWidth,
														} = getRendererCoverPictureInfo(contentQueryData);
														// getting info for auto conversion
														const { queryDataId, editorProperty, fabricEditorSupported } =
															getAutoConvertionInfo(contentQueryData?.content?.nodes?.[0]);

														// If live page is archived, we don't show LivePageComponent. Instead, archived live pages will render using the existing ViewPageContentRenderer.
														const isContentLive =
															isLivePageOnSSR ||
															(contentQueryData?.content?.nodes?.[0]?.subType ===
																LIVE_PAGE_SUBTYPE &&
																contentStatus !== 'archived');

														if (isLivePagesFeatureEnabled) {
															setLivePageGlobalState(isContentLive);

															if (
																isContentLive &&
																!contentQueryError &&
																!!contentIdFromQuery &&
																!renderLivePageAsViewPage
															) {
																return (
																	<NativeCollabPresenceStateContainer scope={contentId}>
																		<LivePageComponent
																			contentId={contentIdFromQuery}
																			spaceKey={spaceKey}
																			ClassicEditorContextProviderLoader={
																				ClassicEditorContextProviderLoader
																			}
																			customPerfData={customPerfData}
																		/>
																	</NativeCollabPresenceStateContainer>
																);
															}
														}
														const renderContentTitle = () => (
															<ContentTitleWithByLine
																banner={<ArchivedPageBanner contentId={contentId} />}
																contentId={contentId}
																spaceKey={spaceKey || ''}
																hasCoverPicture={hasCoverPicture}
																pageWidthType={pageWidthType}
																isEmbeddedPage={isEmbeddedPage}
																shouldShowBylineReactions={shouldShowBylineReactions}
																contentType={contentType}
																contentSubType={contentSubType}
																spaceId={spaceId}
																{...titleAndByLineOptions}
															/>
														);

														if (!!setHasCoverPicture) {
															setHasCoverPicture(hasCoverPicture);
														}

														const livePagePublishExperienceState =
															experienceTracker.getExperienceState(LIVE_PAGE_PUBLISH_EXPERIENCE);
														if (
															livePagePublishExperienceState &&
															!livePagePublishExperienceState.hasStopped &&
															livePagePublishExperienceState.attributes?.contentId === contentId
														) {
															experienceTracker.succeed({
																name: LIVE_PAGE_PUBLISH_EXPERIENCE,
															});
														}

														return (
															<Fragment>
																<ScrollToPageStart
																	key={`ScrollToPageStart-${contentId}`}
																	isSmooth={false}
																/>
																{isThemed && contentHeader}
																<CustomHeaderFooterLoader
																	type={CUSTOM_HEADER_FOOTER_TYPES.HEADER}
																/>
																{hasCoverPicture && (
																	<RendererPageCoverPicture
																		contentId={contentId}
																		isPagePreview={false}
																		coverPicture={coverPicture}
																		contentAppearance={contentAppearance}
																		isSpaceOverview={false}
																		coverPictureWidthOverride={coverPictureWidth}
																	/>
																)}
																<AutoConversionByLineContextProvider
																	editorProperty={editorProperty}
																	fabricEditorSupported={fabricEditorSupported}
																	queryDataId={queryDataId}
																>
																	{renderContentTitle()}
																</AutoConversionByLineContextProvider>
																{/* Body of content */}
																{showPrompt && isPremiumTipPromptEligible && (
																	<AutomationPremiumTipLoader source={source} />
																)}
																<ViewPageContentLoader
																	hasInlineComments={hasInlineComments}
																	contentId={contentId}
																	data={contentQueryData}
																	error={contentQueryError}
																	spaceKey={spaceKey}
																	eventHandlerOverrides={eventHandlers}
																	appearance={appearance as RendererAppearance}
																	hasInlineActions={hasInlineActions}
																	lastModifiedDate={lastModifiedDate || undefined}
																	isEmbeddedPage={isEmbeddedPage}
																	customPerfData={customPerfData}
																/>
																{hasInlineComments && (
																	<InlineCommentsHighlighterLoader
																		pageId={contentId}
																		isFabricPage={isFabricPage}
																	/>
																)}
																<ViewCommentShortcutListener
																	isFabricPage={isFabricPage}
																	contentId={contentId}
																/>
																{isReadingAidsAutoHighlightEnabled && (
																	<ReadingAidsAcronymsHighlighter contentId={contentId} />
																)}

																{hasLabels && (
																	<ViewPageLabelsLoader
																		pageWidthType={pageWidthType}
																		contentId={contentId}
																		contentType={contentType}
																		spaceKey={spaceKey}
																		spaceId={spaceId}
																		contentSubType={contentSubType}
																	/>
																)}
																{/* Render all secondary content - likes, labels, comments, etc. */}
																{hasEOPRecs &&
																	(contentType == 'page' || contentType == 'blogpost') && (
																		<EndOfPageRecommendation
																			entityType={contentType || ''}
																			entityId={contentId}
																			spaceKey={spaceKey || ''}
																			pageWidthType={pageWidthType}
																		/>
																	)}

																{/* eslint-disable-next-line check-react-ssr-usage/no-react-ssr */}
																{!process.env.REACT_SSR && (
																	<ContentViewedEvent contentId={contentId} />
																)}
																{isFabricPage && (
																	<ObjectSidebarControl
																		hasCommentsButton
																		hasDetailsButton
																		hasLoomButton
																		hasAiButton={
																			showAIButton &&
																			fg('confluence_ai_uber_button_in_object_sidebar')
																		}
																		contentId={contentId}
																		spaceKey={spaceKey}
																		contentType={contentType}
																		lastModifiedDate={lastModifiedDate}
																		pageCommentsSectionInView={pageCommentsSectionInView}
																		hasReactionsButton={
																			!isSpaceOverviewPage &&
																			fg('confluence_new_reactions_entry_point_changes')
																		}
																		reactionsPickerPreventOverflowOptions={{
																			// using container incase of overflow caused by other new components
																			boundary:
																				typeof document !== 'undefined'
																					? document.getElementById('AkMainContent')
																					: 'scrollParent',
																			altAxis: true,
																			altBoundary: true,
																			padding: { top: 59 }, // need to account for the progressive header height
																		}}
																	/>
																)}
																{hasReactions &&
																!expValEquals(
																	'cc-page-experiences-new-renderer-byline',
																	'cohort',
																	'test',
																) ? (
																	/* Disable unsupported reactions when rendering live page as view page */ !isLivePage && (
																		<ReactionsProvider contentId={contentId}>
																			<ViewPageReactions
																				hasReactions={hasReactions}
																				contentId={contentId}
																				contentType={contentType}
																				spaceId={spaceId}
																				contentSubType={contentSubType}
																			/>
																		</ReactionsProvider>
																	)
																) : (
																	<Divider testId="reaction-placeholder-divider" />
																)}
																{displayGenericPremiumTip && (
																	<GenericPremiumTipLoadable
																		isCommentSectionInView={pageCommentsSectionInView}
																	/>
																)}
																{hasPageComments &&
																	/* Disable unsupported page comments when rendering live page as view page */ !isLivePage && (
																		<div ref={pageCommentsSectionRef}>
																			{!fg('confluence-frontend-comments-new-aligned-ui') && (
																				<ViewPageFooterComments
																					key={contentId}
																					contentId={contentId}
																					loadingPriority={setLoadingPriority(hasPageComments)}
																					classicComments={classicComments}
																					hasReactions={hasReactions}
																					isFabric={isFabricPage}
																				/>
																			)}
																			{fg('confluence-frontend-comments-new-aligned-ui') && (
																				<ObjectCommentWrapper showGeneralComment />
																			)}
																		</div>
																	)}

																<CustomHeaderFooterLoader
																	type={CUSTOM_HEADER_FOOTER_TYPES.FOOTER}
																/>
																{isFabricPage && fg('confluence_frontend_comments_panel_v2') && (
																	<PageCommentsPubSubHandler
																		contentId={contentId}
																		contentType={contentType}
																	/>
																)}
																{hasQuickReload && (
																	<QuickReload
																		contentId={contentId}
																		contentType={contentType}
																		// The 'Date.now()' is expedient; see notes in quick-reload
																		// package on what a more correct lastPollTime value would be.
																		lastPollTime={Date.now()}
																		pageMode={PageMode.RENDERER}
																		pageCommentsSectionInView={pageCommentsSectionInView}
																		isFabricPage={isFabricPage}
																	/>
																)}
																{hasSatisfactionSurveys && (
																	<SatisfactionSurveysLoader
																		contentId={contentId}
																		isEditorExpEnabled={fg('cc_onboarding_experience')}
																	/>
																)}
																{contentType === 'page' && (
																	<>
																		<LocalActivityViewPageTracker contentId={contentId} />
																		{isLivePagesFeatureEnabled && (
																			<LivePagesChangeboardingManagerLoader origin="view-page" />
																		)}
																	</>
																)}
																{children}
																{hasInlineComments && (
																	<ViewPageInlineComments
																		key={contentId}
																		loadingPriority={setLoadingPriority(hasInlineComments)}
																		contentId={contentId}
																		isFabricPage={isFabricPage}
																	/>
																)}
																{isLivePagesFeatureEnabled && contentType === 'page' && (
																	<ConvertToLivePageFlag />
																)}
																{showAIButton &&
																	(showStandaloneAIButton || showAIButtonForTinyMCE) && (
																		<AIFloatingContextMenu
																			spaceKey={spaceKey ?? ''}
																			contentId={contentId}
																			contentType={contentType}
																			lastModifiedDate={lastModifiedDate}
																			pageCommentsSectionInView={pageCommentsSectionInView}
																		/>
																	)}
															</Fragment>
														);
													}}
												</ViewPageContainer>
											)}
										</ChoreographerAssetsContextProvider>
									</AnnotationsProvider>
								</RendererActionsProvider>
							</ContentRefetchProvider>
						</DocumentUpdateStatusProvider>
					</PageCommentsCountProvider>
				</NewCommentsProvider>
			</ContentOwnershipContextProvider>
		</Fragment>
	);
};
